import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import logo from "../../images/logos/logo.png"
import otefIsraelTitleImage from '../../images/מהתמגנות להסרת האיום.jpg';
import facebookLogo from "../../images/logos/facebook-color.png"
import instagramLogo from "../../images/logos/instagram-color.png"
import whatsappLogo from "../../images/logos/whatsapp-color.png"
import youtubeLogo from "../../images/logos/youtube-color.png"
import twitterLogo from "../../images/logos/twitter-color.png"
import gmailLogo from "../../images/logos/gmail-color.png"
import payboxLogo from "../../images/logos/paybox.png"


function LandingPage({ isContactPage }) {
  const contactLogos = <div>
    {!isContactPage && <div>
      <br className='desktop' />
      <Row>
        <Col xs={12} id="home-title">
          <Image src={otefIsraelTitleImage} className='middle' rounded />
        </Col>
      </Row>
    </div>}
    <section className="scene_element scene_element--fadein">
      <Row className='text-center'>
        <Col xs="6" lg="4">
          <div className="logo-box">
            <a href="https://www.facebook.com/forumotefil" target="_blank" rel="noreferrer">
              <img src={facebookLogo} className="d-inline-block align-top" alt="פייסבוק" />
            </a>
            <h4>בפייסבוק</h4>
          </div>
        </Col>
        <Col xs="6" lg="4">
          <div className="logo-box">
            <a href="https://www.instagram.com/forum_otef_israel" target="_blank" rel="noreferrer">
              <img src={instagramLogo} className="d-inline-block align-top" alt="אינסטגרם" />
            </a>
            <h4>באינסטגרם</h4>
          </div>
        </Col>
        <Col xs="6" lg="4">
          <div className="logo-box">
            <a href="https://chat.whatsapp.com/IN501enbMfLBTk25FGHP1Q" target="_blank" rel="noreferrer">
              <img src={whatsappLogo} className="d-inline-block align-top" alt="וואטסאפ" />
            </a>
            <h4>בוואטסאפ</h4>
          </div>
        </Col>
        <Col xs="6" lg="4">
          <div className="logo-box">
            <a href="https://www.youtube.com/@forumotefisrael" target="_blank" rel="noreferrer">
              <img src={youtubeLogo} className="d-inline-block align-top" alt="יוטיוב" />
            </a>
            <h4>ביוטיוב</h4>
          </div>
        </Col>
        <Col xs="6" lg="4">
          <div className="logo-box">
            <a href="https://twitter.com/forumotefisrael" target="_blank" rel="noreferrer">
              <img src={twitterLogo} className="d-inline-block align-top" alt="טוויטר" />
            </a>
            <h4>בטוויטר</h4>
          </div>
        </Col>

        {!isContactPage &&
          < Col xs="6" lg="4">
            <div className="logo-box">
              <a href="https://forumotefisrael.com/" target="_blank" rel="noreferrer">
                <img src={logo} className="d-inline-block align-top" alt="בית" />
              </a>
              <h4>באתר שלנו</h4>
            </div>
          </Col>
        }
        {isContactPage &&
          <Col xs="6" lg="4">
            <div className="logo-box">
              <a href="tel:0522584610" target="_blank" rel="noreferrer">
                <i className="bi bi-telephone"></i>
              </a>
              <h4>צרו קשר</h4>
            </div>
          </Col>
        }
      </Row>
      {!isContactPage && <Row className='text-center' style={{ margin: "0 auto", marginBottom: "40px" }}>
        <Col xs="6" lg="4">
          <div className="logo-box">
            <a href="https://payboxapp.page.link/gzyHdSgsv95gYUEd9" target="_blank" rel="noreferrer">
                <img src={payboxLogo} className="d-inline-block align-top" alt="תרומה" style={{ borderRadius: "10px", margin: "10px" }} />
            </a>
            <h4>תרומה בפייבוקס</h4>
          </div>
        </Col>
        <Col xs="6" lg="4">
          <div className="logo-box">
            <a href="https://pe4ch.com/ref/RYvCESaE2che?lang=he" target="_blank" rel="noreferrer">
              <i className="bi bi-chat-heart"></i>
            </a>
            <h4>תרמו לנו</h4>
          </div>
        </Col>

        <Col xs="6" lg="4">
          <div className="logo-box">
            <a href="https://pe4ch.com/ref/RYvCESaE2che?lang=en" target="_blank" rel="noreferrer">
              <i className="bi bi-chat-heart"></i>
            </a>
            <h4>Donate us</h4>
          </div>
        </Col>
        <Col xs="6" lg="4">
          <div className="logo-box" title="forumotefisrael@gmail.com">
            <a href="mailto:forumotefisrael@gmail.com?subject=הצטרפות לפורום עוטף ישראל&body=אני מעוניין לשמוע עוד מידע נוסף על פורום עוטף ישראל" target="_blank" rel="noreferrer">
              <img src={gmailLogo} className="d-inline-block align-top" alt="אימייל" />
            </a>
            <h4 >כתוב לנו</h4>
          </div>
        </Col>
        <Col xs="6" lg="4" className='desktop'>
          <div className="logo-box">
            <a href="tel:0522584610" target="_blank" rel="noreferrer">
              <i className="bi bi-telephone"></i>
            </a>
            <h4>צרו קשר</h4>
          </div>
        </Col>
      </Row>
      }

    </section>
  </div >;
  if (isContactPage) {
    return <div id="LandingPage" style={{ maxWidth: "600px" }} className='middle'>
      {contactLogos}
    </div>
  }
  return (
    <Container id="LandingPage" style={{ maxWidth: "800px", paddingTop: "10px" }}>
      {contactLogos}
    </Container>
  );
}

export default LandingPage;

