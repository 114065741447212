import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';


function Events() {
  let eventsImages = require('../../lib/media/eventsImages.json');
  return (
    <Container>
      <section className="scene_element scene_element--fadein">
        <Row>
          <Col sm="12">
            <h1 className='page-title-box'>אירועים</h1>
          </Col>
          <Col sm="12" className='text-center event-section'>
            {
              eventsImages.toReversed().map((item, i) => {
                return (<div key={i}>
                  <h1>{item.title}</h1>
                  <Card>
                    <img
                      key={i}
                      className=""
                      src={`../images/events/${item.image}`}
                      alt={item.title}
                    />
                  </Card>
                  <br /><hr /><br />
                </div>)
              })
            }
          </Col>
        </Row>
        <br /><br />

      </section>
    </Container>
  );
}

export default Events;