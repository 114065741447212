import { collection, query, getDocs, addDoc, setDoc, deleteDoc, orderBy,  doc } from 'firebase/firestore';
import firebaseStore from '../components/common/lib/FirbaseStoreConfig';


const ContactService = {
    results: [],
    async getMediaList(collectionName = 'images') {
        const q = query(collection(firebaseStore, collectionName), orderBy('order', 'desc'));
        const querySnapshot = await getDocs(q);
        const results = [];
        querySnapshot.forEach(function (doc) {
            const docObj = doc.data();
            docObj.id = doc.id;
            results.push(docObj);
        });
        return results
    },
    async updateMediaList(collectionName, mediaList) {
        const collectionRef = collection(firebaseStore, collectionName);

        mediaList.forEach(async (item) => {
            if (item.title ==='') {
                return;
            }
            try {
                if (!item.title) return;

                if (item.id) {
                    const docRef = doc(firebaseStore, collectionName, item.id);
                    if (!item.deleted) {
                        await setDoc(docRef, item, { merge: true });
                    } else {
                        await deleteDoc(docRef);
                    }
                    console.log(`Document with ID ${item.id} updated successfully`);
                } else {
                    if (!item.deleted) {
                        item.createdAt = new Date();
                        const docRef = await addDoc(collectionRef, item);
                        item.id = docRef.id;
                        await setDoc(docRef, item, { merge: true });
                    }
                }
            } catch (err) {
                console.error(`Error updating document with ID ${item.id}: `, err.message);
            }
        });

        // console.log('All documents updated successfully');
        // return setDoc(doc(firebaseStore, "contacts", docObj.id), docObj, { merge: true });
    }
}

export default ContactService;