import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

function Articles() {
  let articlesImages = require('../../lib/media/articlesImages.json');
  return (
    <Container>
      <section className="scene_element scene_element--fadein">
        <Row>
          <Col sm="12">
            <h1 className='page-title-box'>כתבות</h1>
          </Col>
          <Col sm="12" className='text-center article-section'>
            {
              articlesImages.toReversed().map((item, i) => {
                return (<div key={i}>
                  <h1>{item.title}</h1>
                  <Card>
                    <a href={item.link} target="_blank" rel="noreferrer">
                        <img
                          className=""
                          src={`../images/articels/${item.image}`}
                          alt={item.title}
                        />
                      </a>
                  </Card>
                  <br /><hr /><br />
                </div>)
              })
            }
          </Col>
        </Row>
        <br /><br />

      </section>
    </Container>
  );
}

export default Articles;